/**
 * Api of delivery address
 */

// My module
import httpRequest from '../utils/http.request.utils';
import { refreshUser } from './auth.request';
import { ERouteApi } from './routes.definitions';
import {
  IUserConciergeryDelivery,
  IUserDefaultDelivery,
  IUserDeliveryAddress,
  IUserUpdateDeliveryAddress,
} from './types';

/**
 * Function get billing address user parameter in app
 */
export async function getUserDeliveryAddress(data: IUserDeliveryAddress) {
  const response = await httpRequest({
    dispatch: data.dispatch,
    url: ERouteApi.userDeliveryAddress,
    method: 'post',
    data: {},
  });

  await refreshUser(data.dispatch);

  return response;
}

/**
 * Function to create conciergery address
 */
export async function createUserAddressConciergery(data: IUserConciergeryDelivery) {
  const url = ERouteApi.getUserConciergeryDelivery
    .replace('$user_id', data.user_id)
    .replace('$odooConciergerieId', data.odooConciergerieId) as ERouteApi;

  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    method: 'post',
    data: data.data,
  });

  await refreshUser(data.dispatch);

  return response;
}

/**
 * Function update user address delivery
 */
export async function userUpdateDeliveryAddress(data: IUserUpdateDeliveryAddress) {
  const url = ERouteApi.updateDeliveryAddress
    .replace('$user_id', data.user_id)
    .replace('$address_id', data.address_id) as ERouteApi;

  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    method: 'PATCH',
    data: data.data,
  });

  await refreshUser(data.dispatch);

  return response;
}

// change delivery default from user
export async function setDefaultDelivery(data: IUserDefaultDelivery) {
  const url = ERouteApi.updateDefaultDeliveryAddress
    .replace('$user_id', data.user_id)
    .replace('$address_id', data.address_id) as ERouteApi;

  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    method: 'PATCH',
    data: {},
  });

  await refreshUser(data.dispatch);

  return response;
}
