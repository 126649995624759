/* eslint-disable */
import moment from 'moment';
import 'moment/locale/fr';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import CacheBuster from './CacheBuster';
import { getConfig } from './api/api.config.request';
import { getTokenFromServer } from './api/auth.request';
import { EStorageKey } from './config/definition.storage.key';
import UpdateAppModalContainer from './modules/update-app-modal/containers/update-app-modal.containers';
import NavigationIndex from './navigation/navigation-index';
import { setJson } from './store/features/config.slice';
import { AppDispatch } from './store/store';
import { EStatusCode } from './utils/types';

const App: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const updateAccessTokenInLocalStorage = async () => {
    try {
      const userDataJson = localStorage.getItem(EStorageKey.USER_PARAMS);

      // when user is not connected, there is no user data in local storage
      if (!userDataJson) {
        console.error(
          'Error: Could not update access token - user data is missing from local storage'
        );
        return;
      }

      const userData = JSON.parse(userDataJson);

      if (!userData.accessToken) {
        console.error(
          'Error: Could not update access token - user data is missing the access token property'
        );
        return;
      }

      const response = await getTokenFromServer();

      if (response.status !== EStatusCode.success) {
        console.error(
          `Error: Could not update access token - server responded with status ${response.status}`
        );
        return;
      }

      const accessToken = response?.json?.data?.accessToken;

      if (accessToken) {
        const updatedUserData = {
          ...userData,
          accessToken,
        };
        localStorage.setItem(EStorageKey.USER_PARAMS, JSON.stringify(updatedUserData));
      }
    } catch (error) {
      console.error('Error: Could not update access token - an error occurred', error);
    }
  };

  const init = async () => {
    const config = await getConfig(dispatch);

    if (config !== null) {
      dispatch(setJson(config));
    }

    updateAccessTokenInLocalStorage();
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <CacheBuster>
      {(params: any) => {
        if (params.loading) return null;
        if (!params.loading && !params.isLatestVersion) {
          params.refreshCacheAndReload();
        }
        // setting moment
        moment.locale('fr');
        moment().format('ll');
        return (
          <BrowserRouter>
            <NavigationIndex />
            <UpdateAppModalContainer />
          </BrowserRouter>
        );
      }}
    </CacheBuster>
  );
};

export default App;
