// https://mw.please-it.com/assets/Please/config-app-pub.json

// my custom import
import config from '../config/config.json';
import httpRequest from '../utils/http.request.utils';
import { EStatusCode } from '../utils/types';
import { ERouteApi } from './routes.definitions';

// get config general
export async function getConfig(dispatch: any) {
  const response = await httpRequest({
    dispatch: dispatch,
    url: ERouteApi.generalConfig,
    method: 'get',
    data: {},
    overideRequest: true,
  });

  if (response?.status === EStatusCode.success && response?.json) {
    return response.json;
  }

  return null;
}

export function getLocalConfig() {
  return config;
}
