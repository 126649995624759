/**
 * Api of user validate phone number sms code
 */
import httpRequest from '../utils/http.request.utils';
import { refreshUser } from './auth.request';
import { ERouteApi } from './routes.definitions';
import {
  IPropsContractOfferEnterprise,
  IPropsGenerateCouponEnterpriseOffer,
  IPropsGetListOfferEntreprise,
  IPropsOfferEntrepriseAdd,
  IPropsRemoveOfferEnterprise,
  IPropsSearchEntreprise,
  IPropsSetDefaultDeliveryPro,
} from './types';

export async function searchEntrepriseByCode(data: IPropsSearchEntreprise) {
  const url = ERouteApi.searchEntrepriseByCode
    .replace('$status', data.status)
    .replace('$token', data.token)
    .replace('$with-addresses', 'true')
    .replace('$with-company', 'true') as ERouteApi;

  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    method: 'get',
    overideRequest: true,
    data: {
      token: data.token,
      status: data.status,
    },
  });

  return response;
}

// signup user for enterprise offer
export async function addEntrepriseOffer(data: IPropsOfferEntrepriseAdd) {
  const url = ERouteApi.addUserEntrepriseOffer.replace('$mw_id', data.mw_id) as ERouteApi;

  // /ms-identity/v1/users/mw-id/{mw_id}
  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    method: 'put',
    overideRequest: true,
    data: {
      odoo_id: data.odoo_id,
      odoo_conciergerie_id: data.odoo_conciergerie_id,
      mw_address_id: data.mw_address_id,
      benefit_contracts: {
        benefit_contract_id: data.benefit_contracts.benefit_contract_id,
        token: data.benefit_contracts.token,
      },
    },
  });

  await refreshUser(data.dispatch);

  return response;
}

export async function getListOfferEnterprise(data: IPropsGetListOfferEntreprise) {
  const url = ERouteApi.getListOfferEntreprise
    .replace('$mw_id', data.mw_id)
    .replace('$type', data.type)
    .replace('$withService', data.withService) as ERouteApi;

  // /ms-identity/v1/users/mw-id/{mw_id}
  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    method: 'get',
    overideRequest: true,
    data: {},
  });

  return response;
}

export async function getContractOfferEnterprise(data: IPropsContractOfferEnterprise) {
  const url = ERouteApi.getContractOfferEnterprise.replace(
    '$mw_id',
    data.mw_id
  ) as ERouteApi;

  // /ms-identity/v1/users/mw-id/{mw_id}
  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    method: 'get',
    overideRequest: true,
    data: {},
  });

  return response;
}

export async function setDefaultDeliveryPro(data: IPropsSetDefaultDeliveryPro) {
  const url = ERouteApi.setDefaultDeliveryPro.replace('$mw_id', data.mw_id) as ERouteApi;

  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    method: 'put',
    overideRequest: true,
    data: {
      mw_address_id: data.mw_address_id,
      odoo_conciergerie_id: data.odoo_conciergerie_id,
    },
  });

  await refreshUser(data.dispatch);

  return response;
}

// Create coupon of server to generate value
export async function generateCouponOfferEntreprise(
  data: IPropsGenerateCouponEnterpriseOffer
) {
  const response = await httpRequest({
    dispatch: data.dispatch,
    url: ERouteApi.generateCouponOfferEntreprise,
    method: 'post',
    overideRequest: true,
    data: {
      amount: data.amount,
      odooUserId: data.odooUserId,
      serviceId: data.serviceId,
    },
  });

  return response;
}

// User remove in subscribe enterprise Offer
export async function removeUserOfferEnterprise(data: IPropsRemoveOfferEnterprise) {
  const url = ERouteApi.removeOfferEnterprise
    .replace('$mw_id', data.mw_id)
    .replace('$benefitContractId', data.benefitContractId) as ERouteApi;

  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    method: 'delete',
    overideRequest: true,
    data: {},
  });

  await refreshUser(data.dispatch);

  return response;
}
