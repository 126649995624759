/**
 * Api of user update/get info
 */

// My module
import httpRequest from '../utils/http.request.utils';
import { EStatusCode } from '../utils/types';
import { refreshUser } from './auth.request';
import { ERouteApi } from './routes.definitions';
import {
  IOfferMarketingUpdate,
  IPasswordTokenValid,
  IRemoveUserAccount,
  IResetPasswordToken,
  IUpdateAccountUserInfo,
  IUpdateCguUser,
  IUpdatePassword,
  IUserInfo,
} from './types';

export async function getPasswordTokenValid(data: IPasswordTokenValid) {
  // api/public/user/password/reset/valid/63dad01f-a10d-4cad-b713-47d32076e7ab
  const url = ERouteApi.getPasswordTokenValid.replace('$token', data.token) as ERouteApi;

  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    data: {},
    method: 'get',
  });

  return response;
}

export async function setResetPassword(data: IResetPasswordToken) {
  const url = ERouteApi.setResetPassword.replace('$token', data.token) as ERouteApi;

  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    data: {
      password: data.password,
    },
    method: 'patch',
  });

  await refreshUser(data.dispatch);

  return response;
}

/**
 * Function get star user shop favorite
 */
export async function updateAccountUserInfo(data: IUpdateAccountUserInfo) {
  const url = ERouteApi.accountUserInfo.replace('$id', data.userInfo.id) as ERouteApi;

  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    data: data.userInfo,
    method: 'put',
  });

  await refreshUser(data.dispatch);

  return response;
}
// get user info
export async function userAccountInfo(data: IUserInfo) {
  const response = await httpRequest({
    dispatch: data.dispatch,
    url: ERouteApi.getAccountUserInfo,
    method: 'get',
    data: {},
    overideRequest: true,
  });

  if (response?.status === EStatusCode.success && response?.json?.data) {
    return response.json.data;
  }

  // Before save in json
  return null;
}

export async function updateCguUser(data: IUpdateCguUser) {
  const url = ERouteApi.userCgu.replace('$id', data.user_id.toString()) as ERouteApi;

  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    method: 'PATCH',
    data: {
      cguDate: data.cguDate,
      cguId: data.cguId,
      cguValid: data.cguValid,
      id: data.user_id,
    },
  });

  await refreshUser(data.dispatch);

  // Before save in json
  return response;
}

// user update marketing checking cgu private life page
export async function updateOfferMarketing(data: IOfferMarketingUpdate) {
  const url = ERouteApi.userMarketingUpdate.replace(
    '$id',
    data.id?.toString() || ''
  ) as ERouteApi;

  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    method: 'PATCH',
    data: {
      id: data.id,
      marketingSubscription: data.marketingSubscription,
    },
  });

  await refreshUser(data.dispatch);
  // Before save in json
  return response;
}

// user update marketing checking cgu private life page
export async function getDeleteReasons(data: { dispatch: any }) {
  const response = await httpRequest({
    dispatch: data.dispatch,
    url: ERouteApi.getDeleteReasons,
    method: 'get',
    data: {},
  });

  // Before save in json
  return response;
}

// user update password
export async function updatePasswordUser(data: IUpdatePassword) {
  const response = await httpRequest({
    dispatch: data.dispatch,
    url: ERouteApi.updatePasswordUser,
    method: 'PATCH',
    data: {
      newPassword: data.newPassword,
      oldPassword: data.oldPassword,
    },
  });

  await refreshUser(data.dispatch);

  // Before save in json

  return response;
}

// user remove account for reasons
export async function removeUserAccount(data: IRemoveUserAccount) {
  const response = await httpRequest({
    dispatch: data.dispatch,
    url: ERouteApi.deleteUserAccount,
    method: 'delete',
    data: {
      reasonId: data.reasonId,
      comment: data.comment,
    },
  });

  await refreshUser(data.dispatch);

  // Before save in json
  return response;
}
