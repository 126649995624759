/**
 * Api of billing address
 */

// My module
import httpRequest from '../utils/http.request.utils';
import { refreshUser } from './auth.request';
import { ERouteApi } from './routes.definitions';
import {
  IPropsRemoveUserBillingAddress,
  IUserBillingAdddress,
  IUserUpdateDeliveryAddress,
} from './types';

/**
 * Function get billing address user parameter in app
 */
export async function getUserBillingAddress(data: IUserBillingAdddress) {
  const url = ERouteApi.userBillingAddress.replace('$id', data.user_id) as ERouteApi;
  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    method: 'get',
    data: {},
  });

  return response;
}

// update user billing address
export async function updateUserBillingAddress(data: IUserUpdateDeliveryAddress) {
  const url = ERouteApi.updateDeliveryAddress
    .replace('$user_id', data.user_id)
    .replace('$address_id', data.address_id) as ERouteApi;

  await httpRequest({
    dispatch: data.dispatch,
    url: url,
    method: 'PATCH',
    data: data.data,
  });

  await refreshUser(data.dispatch);
}

// remove user billing address
export async function removeUserBillingAddress(data: IPropsRemoveUserBillingAddress) {
  const url = ERouteApi.removeUserBillingAddress
    .replace('$user_id', data.user_id)
    .replace('$address_id', data.address_id) as ERouteApi;

  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    method: 'delete',
    data: {},
  });

  await refreshUser(data.dispatch);

  return response;
}
