/**
 * Api of user validate phone number sms code
 */
import httpRequest from '../utils/http.request.utils';
import { refreshUser } from './auth.request';
import { ERouteApi } from './routes.definitions';
import {
  IPropsPhoneNumber,
  IPropsPhoneNumberConfirm,
  IProsPhoneNumberExist,
} from './types';

// user validate phone number with sms send
export async function sendSmsValidatePhoneNumber(data: IPropsPhoneNumber) {
  const response = await httpRequest({
    dispatch: data.dispatch,
    url: ERouteApi.userSendSmsValidatePhoneNumber,
    method: 'post',
    data: {
      mobileNumber: data.mobileNumber,
    },
  });

  return response;
}

// user validate phone number with sms send
export async function phoneNumberExist(data: IProsPhoneNumberExist) {
  const url = ERouteApi.issetPhoneNumber.replace('$mobileNumber', data.mobileNumber);

  const response = await httpRequest({
    dispatch: data.dispatch,
    url,
    method: 'get',
    data: {},
  });

  return response;
}

// Send confirm code receveid of sms
export async function sendConfirmCodeSms(data: IPropsPhoneNumberConfirm) {
  const response = await httpRequest({
    dispatch: data.dispatch,
    url: ERouteApi.sendConfirmCodeSms,
    method: 'post',
    data: {
      code: data.codeNumber,
    },
  });

  await refreshUser(data.dispatch);

  return response;
}
