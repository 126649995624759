/* eslint-disable array-bracket-spacing */
/**
 * LocalStorage cart user
 * Add in cart product, Remove in cart, Get in cart
 *
 */

// My import
import isUndefined from 'lodash/isUndefined';
import { ICartArticle } from '../components/supplement-modal/types';
import { EStorageKey } from '../config/definition.storage.key';
import { stateRefreshCart } from '../reducers/State/action';
import httpRequest from '../utils/http.request.utils';
import { getData, storeData } from '../utils/local.storage.utils';
import { ERouteApi } from './routes.definitions';
import { ICart, IGetShoppingCartAmounts } from './types';

// check user change conciergery cart is valid
export async function checkCartValidConciergery(data: {
  dispatch: any;
  serviceId: string;
  addressId: string;
}) {
  // v1/services/$serviceId/users/$addressId/is-eligible
  const url = ERouteApi.isEligibleCart
    .replace('$serviceId', data.serviceId)
    .replace('$addressId', data.addressId) as ERouteApi;

  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    method: 'get',
    data: {},
    overideRequest: true,
  });

  return response;
}

const reRenderCart = (dispatch: any) => {
  const refreshCart = {
    refreshCart: true,
  };

  dispatch(stateRefreshCart(refreshCart));
};

const createShoppingCart = (article: ICartArticle, serviceId: number, dispatch: any) => {
  const data: ICart = {
    serviceId,
    articles: [article],
    voucherCode: '',
  };

  storeData(data, EStorageKey.CART_USER);

  reRenderCart(dispatch);
};

export const addArticleToCart = (
  article: ICartArticle,
  serviceId: number,
  dispatch: any
) => {
  const cart: ICart = getData(EStorageKey.CART_USER);

  if (cart == null || cart.serviceId != serviceId) {
    return createShoppingCart(article, serviceId, dispatch);
  }

  cart.articles.push(article);

  storeData(cart, EStorageKey.CART_USER);

  reRenderCart(dispatch);
};

export const removeArticleFromCart = (index: number | undefined, dispatch: any) => {
  const cart: ICart = getData(EStorageKey.CART_USER);

  if (
    cart == null ||
    isUndefined(index) ||
    index < 0 ||
    index > cart.articles.length - 1
  ) {
    return;
  }

  cart.articles.splice(index, 1);

  if (cart.articles.length === 0) {
    removeCart(dispatch);
  } else {
    storeData(cart, EStorageKey.CART_USER);
    reRenderCart(dispatch);
  }
};

export const updateCartArticle = (
  article: ICartArticle,
  index: number | undefined,
  dispatch: any
) => {
  const cart: ICart = getData(EStorageKey.CART_USER);

  if (
    cart == null ||
    isUndefined(index) ||
    index < 0 ||
    index > cart.articles.length - 1
  ) {
    return;
  }
  cart.articles[index] = article;
  storeData(cart, EStorageKey.CART_USER);

  reRenderCart(dispatch);
};

// get id of boutique saved in card if not empty
export async function getBoutiqueIdOfCart() {
  const data = await getData(EStorageKey.CART_USER);

  return data !== null ? data : null;
}

// remove cart
export async function removeCart(dispatch: any) {
  storeData(null, EStorageKey.CART_USER);

  reRenderCart(dispatch);
}

export const getShoppingCartAmounts = async (data: IGetShoppingCartAmounts) => {
  const url = ERouteApi.createShoppingCart;

  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    method: 'post',
    data: data.body,
    overideRequest: true,
  });

  return response;
};

export const getCartArticlesNumber = () => {
  const cart: ICart = getData(EStorageKey.CART_USER);

  const articleNbr = cart?.articles?.reduce(
    (accumulator, currentArticle) => accumulator + currentArticle.quantity,
    0
  );

  return articleNbr || 0;
};
