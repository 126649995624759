/**
 * Listing of route in app
 */

/* eslint-disable max-len */

export enum ERouteApi {
  // User routes
  userCgu = 'api/user/$id',
  deleteUserAccount = 'api/user/account',
  userSendSmsValidatePhoneNumber = 'api/user/mobile/validate/sms',
  sendConfirmCodeSms = 'api/user/mobile/validate',
  userMarketingUpdate = 'api/user/$id',
  accountUserInfo = 'api/user/$id',
  updateConciergeryUser = 'api/user/$id',
  updatePasswordUser = 'api/user/modifyPassword',

  // User address routes
  removeUserBillingAddress = 'api/userAddress/$user_id/$address_id',
  updateDefaultDeliveryAddress = 'api/userAddress/$user_id/defaultDelivery/$address_id',
  updateDeliveryAddress = 'api/userAddress/$user_id/update/$address_id',
  userAddNewAddressDelivery = 'api/userAddress/$user_id/update/$address_id',
  getUserConciergeryDelivery = 'api/userAddress/$user_id?odooConciergerieId=$odooConciergerieId',
  userBillingAddress = 'api/userAddress/$id/withoutBilling',

  // Public routes
  getLegalCgu = 'api/public/cgu/valid?type=CGUH',
  getPasswordTokenValid = 'api/public/user/password/reset/valid/$token',
  setResetPassword = 'api/public/user/password/reset/$token',
  getCategoryDetailByTags = 'api/public/servicetags/tags/$tagid/services/merged',
  availableConciergyForAddress = 'api/public/conciergerie/coordinates?latitude=$latitude&longitude=$longitude',
  getCategoryTags = 'api/public/tags/conciergerie/$id',
  getLegalConfidentiality = 'api/public/cgu/valid/confidentiality-policy?type=CGUH',
  signUp = 'api/public/user',
  cartItemDetails = 'api/public/service/odoo/odooPricelistIds',
  issetEmail = 'api/public/user/email/tracked?email=$email',
  categoryService = 'api/public/category/conciergerie/odooConciergerieId/$id',
  sliderHome = 'api/public/conciergerieImageSlide/odooConciergerie/$id',
  boutiqueDetails = 'api/public/service/odooPriceListId/$id',
  boutiqueDetailsOdoo = 'api/public/service/odoo/odooPricelistIds',
  searchAddress = 'api/public/addresses/find?query=$id',
  existAddress = 'api/public/addresses/find?query=$id',
  issetPhoneNumber = 'api/public/user/mobileNumber/exists?mobileNumber=$mobileNumber',
  unavailableProducts = 'api/public/odoo/orders/verify',
  payments_forms = 'api/public/payments/form?', //'api/public/payments/form?AMOUNT=$total_topay&REFERENCE=$PBX_CMD&PORTEUR=$email&OFFER=$offerId&ORDER_ID=$orderId&PBX_REFABONNE=$refKey',
  getConciergeryList = 'api/public/conciergerie',
  getCategoryPublic = 'api/public/service/category/$id?lat=$lat&lon=$lon',
  headerCategory = 'api/public/category/$id',

  // Odoo routes
  userToBeRated = 'api/odoo/orders/read/to-be-rated',
  checkValidPromo = 'api/odoo/orders/validate/voucher',
  starShopUser = 'api/odoo/coupon',
  offerStatus = 'api/odoo/catalogs/offers/status/$id',
  createOrderCart = 'api/odoo/orders/add',
  orderStatusPayment = 'api/odoo/orders/read',

  // Payments routes
  userCartPayment = 'api/payments/footprint',
  transactionRefKey = 'api/payments/transaction',
  transactionStatusIpn = 'api/payments/transaction/info?REFERENCE=$ref',

  //Reward routes
  getRewardData = 'api/reward/me',
  setUserVipMonths = 'api/reward/vip/QUARTER', // vip 3mois
  convertCoupon = 'api/reward/convert/coupon/$value', // bon d'achat 15€

  // Unsorted routes
  getRewardCouponsEstimated = 'ms-order/v2/shopping-carts/reward-points',
  login = 'login',
  generalConfig = 'assets/Please/config-app-pub-v3.json',
  registerNotification = 'api/notification/writedevice',
  removeNotification = 'api/device/',
  getOfferDeliveyDistance = 'api/service/category/calculateDeliveryDistance',
  recoverPassword = 'recovery-password',
  getDeleteReasons = 'api/deleteReasons',
  getConciergeryHelpers = 'api/conciergerie/$id',
  deliveryMethodDetails = 'api/order/cart/extra/new',
  userDeliveryAddress = 'user-delivery-address',
  starUpdateShopUser = 'user-update-star-shop',
  maintenanceStatus = 'login',
  translationData = 'assets/Please/translate-app-pub.json',
  updateUserBillingAddress = 'update-user-billing-address',
  setUserVipYears = 'v1/public/services?',
  updateOfferMarketing = 'update-offer-marketing',
  sendInvoiceEmail = 'api/invoices/send/$orderId',
  deliverySettingsTransport = 'assets/ProDelivery/config-conciergeries.json',
  updateVersion = 'update-version',
  availableService = 'available-service',
  categoryDetails = 'api/service/category/$id',

  // MS Security routes
  getStatusUpgradeApp = 'ms-security/v1/authorize',

  // MS Payment routes
  getUserCreditCards = 'ms-payment/users/$id/credit-cards',
  userCreditCard = 'ms-payment/users/$id/credit-cards/$cardMongoId',

  // MS Offer v1 routes
  patchConciergeries = 'ms-offer/v1/conciergeries/26',
  getCategoryPublicV2 = 'ms-offer/v1/public/services?category-id=$categoryId&around-me=$aroundMe&longitude=$longitude&latitude=$latitude',
  categoryDetailsV2 = 'ms-offer/v1/services?category-id=$id',
  isEligibleCart = 'ms-offer/v1/services/$serviceId/addresses/$addressId/is-eligible',
  serviceAroundMe = 'ms-offer/v1/services?odoo-conciergerie-id=$odooConciergerieId&from-service-id=$fromServiceId&to-service-id=$toServiceId&limit=$limit&around-me=$aroundMe&longitude=$longitude&latitude=$latitude&status=$status&is-enterprise-offer=false',
  serviceAroundMePublic = 'ms-offer/v1/public/services?odoo-conciergerie-id=$odooConciergerieId&from-service-id=$fromServiceId&to-service-id=$toServiceId&limit=$limit&around-me=$aroundMe&longitude=$longitude&latitude=$latitude&status=$status&is-enterprise-offer=false',
  sliderHomeV2 = 'ms-offer/v1/carrousel/$id',
  getService = 'ms-offer/v1/services/$id',
  searchEntrepriseByCode = 'ms-offer/v1/benefit-contracts?status=$status&token=$token&with-addresses=$with-addresses&with-company=$with-company&with-addresses-details=true&token-status=active',
  getCategoryDetailByTagsV2 = 'ms-offer/v1/services?tag-id=$tag_id',
  getCategoryDetailByTagsPublicV2 = 'ms-offer/v1/public/services?tag-id=$tag_id',
  categoryServiceV2 = 'ms-offer/v1/categories?odoo-conciergerie-id=$id',
  search = 'ms-offer/v1/services/search',
  getPublicService = 'ms-offer/v1/public/services/$id',
  generateCouponOfferEntreprise = 'ms-offer/v1/coupons',

  // MS Address v1 routes
  searchAddressFromCoordinates = 'ms-address/v1/search/tomtom/reverseGeocode?longitude=$longitude&latitude=$latitude',
  searchPostalCode = 'ms-address/v1/logistic-areas?postal_code=$postal_code',
  checkValidityCoordsInConciergery = 'ms-address/v1/logistic-areas/match-neighborhood?coordinates=$coordinates&neighborhood_id=$neighborhood_id',
  getTomTomAdress = 'ms-address/v1/search/tomtom/detail?q=$q&first_result_only=$first_result_only',

  // MS Communication routes
  postInitChat = 'ms-communication/sendbird/app/init-chat',

  // MS Notification routes
  postReportedIssue = 'ms-notification/email/customer-service',
  getNewCgu = 'ms-notification/freshdesk/cgu',

  // new ms order v3
  sendRatingOrder = 'ms-order/v3/orders/$orderId/customerReview',
  ignoreRatingOrder = 'ms-order/v3/orders/$orderId/ignoreCustomerReview',
  userSendClaimOrder = 'ms-order/v3/claims/for-order/$orderId',
  getOrderById = 'ms-order/v3/orders/$id',
  getCustomerOrderById = 'ms-order/v3/orders/$id/customer',
  createShoppingCart = 'ms-order/v3/shopping-carts',
  createOrderFromShoppingCart = 'ms-order/v3/orders/shopping-carts/$id',
  orderHistory = 'ms-order/v3/orders/customer?globalStates=$globalStates&fromDate=$fromDate&toDate=$toDate',

  // MS Identity v1 routes
  lostPassword = 'ms-identity/v1/users/reset-password/send-email',
  getAccountUserInfo = 'ms-identity/v1/users/me',
  tokenCheckAndUpdate = 'ms-identity/v1/users/update-token',
  searchSponsor = 'ms-identity/v1/users/$id/sponsorship-code/$sponsorshipcode',
  addSponsor = 'ms-identity/v1/users/$id/sponsor',
  getSponsor = 'ms-identity/v1/users/$id/sponsor',
  removeSponsor = 'ms-identity/v1/users/$id/sponsor',
  getSponsored = 'ms-identity/v1/users/$id/sponsored',
  getCouponsAvailableV2 = 'ms-identity/v1/users/$odoo_id/vouchers',
  getCouponsAvailable = 'ms-identity/v1/users/$odoo_id/vouchers',
  getRestoreIdFreshChat = 'ms-identity/v1/users/mw-id/$mw_id/restore-id',
  setRestoreIdFreshChat = 'ms-identity/v1/users/mw-id/$mw_id',
  sendMessageUserFresh = 'ms-identity/v1/freshdesk/conversation/user-id/$user_id',
  removeOfferEnterprise = 'ms-identity/v1/users/mw-id/$mw_id/benefitContracts/$benefitContractId', // 'ms-identity/v1/users/mw-id/$mw_id/benefitContracts/$token',
  setDefaultDeliveryPro = 'ms-identity/v1/users/mw-id/$mw_id/address',
  getListOfferEntreprise = 'ms-identity/v1/users/mw-id/$mw_id/default-address/services/$type?with-services=$withService',
  getContractOfferEnterprise = 'ms-identity/v1/users/mw-id/$mw_id/benefit-contracts?with-addresses=true&with-company=true&with-addresses-details=true',
  addUserEntrepriseOffer = 'ms-identity/v1/users/mw-id/$mw_id',
}
