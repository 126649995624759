/**
 * Api of auth request user
 */

// My module
import { EStorageKey } from '../config/definition.storage.key';
import { updateStatus } from '../reducers/Auth/action';
import { updateUser } from '../reducers/User/action';
import { userInitialState } from '../reducers/User/user.reducer';
import httpRequest from '../utils/http.request.utils';
import { getData, storeData } from '../utils/local.storage.utils';
import { userAccountInfo } from './api.user.info.request';
import { ERouteApi } from './routes.definitions';
import {
  IIssetEmail,
  IIssetPhoneNumber,
  ILoginProps,
  IPropsUserAuth,
  IResetPassword,
  ISignUp,
} from './types';

/**
 * Function login user
 * @param data [email, password]
 */
export async function login(data: ILoginProps) {
  const response = await httpRequest({
    dispatch: data.dispatch,
    url: ERouteApi.login,
    method: 'post',
    data: {
      username: data.username,
      password: data.password,
    },
  });

  return response;
}

// User reset password
export async function resetPassword(data: IResetPassword) {
  const response = await httpRequest({
    dispatch: data.dispatch,
    url: ERouteApi.lostPassword,
    method: 'post',
    data: {
      email: data.email,
    },
    overideRequest: true,
  });

  await refreshUser(data.dispatch);

  return response;
}

// Check if email user exist in signup function
export async function issetEmail(data: IIssetEmail) {
  const url = ERouteApi.issetEmail.replace('$email', data.email) as ERouteApi;

  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    method: 'get',
    data: {
      email: data.email,
    },
  });

  return response;
}

// Check if phoneNumber user exist in signup function
export async function issetPhoneNumber(data: IIssetPhoneNumber) {
  const url = ERouteApi.issetPhoneNumber.replace(
    '$mobileNumber',
    encodeURIComponent(data.phoneNumber)
  ) as ERouteApi;

  const response = await httpRequest({
    dispatch: data.dispatch,
    url: url,
    method: 'get',
    data: {},
  });

  return response;
}

// User inscription
export async function signUp(data: ISignUp) {
  const response = await httpRequest({
    dispatch: data.dispatch,
    url: ERouteApi.signUp,
    method: 'post',
    data: {
      billingAddress: data.billingAddress,
      cguDate: data.cguDate,
      cguId: data.cguId,
      cguValid: data.cguValid,
      developerMode: data.developerMode,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      linkedToConciergerie: data.linkedToConciergerie,
      marketingSubscription: data.marketingSubscription,
      mobileNumber: data.mobileNumber,
      odooConciergerieId: data.odooConciergerieId,
      pwd: data.pwd,
      subscribed: data.subscribed,
    },
  });

  return response;
}

// Authentified user in APP
export function setAuthUser(data: IPropsUserAuth) {
  // EStorageKey.USER_PARAMS

  storeData(data, EStorageKey.USER_PARAMS);
  return true;
}

// Return user info params authenfied
export function getUserInfo(): IPropsUserAuth {
  const user = getData(EStorageKey.USER_PARAMS);

  return user;
}

// save params to userSkypAuth
export async function userSkypAuth(data: any) {
  await storeData(data, EStorageKey.USER_PARAMS_NOT_AUTH);
  return;
}

// get data of user to skypped auth
export async function getUserDataSkypedAuth() {
  const user = await getData(EStorageKey.USER_PARAMS_NOT_AUTH);

  return user;
}

// Determine if user auth
export function getIfAuthUser(): boolean {
  const user = getData(EStorageKey.USER_PARAMS);

  if (user === null) return false;
  return true;
}

export async function refreshUser(dispatch: any) {
  const user = await userAccountInfo({ dispatch });

  if (user === null) {
    dispatch(updateUser(userInitialState));

    dispatch(
      updateStatus({
        loggedIn: false,
      })
    );
    return;
  }

  dispatch(updateUser(user));

  dispatch(
    updateStatus({
      loggedIn: true,
    })
  );
}

// Logout user In app
export function logout() {
  //clear local storage
  localStorage.clear();

  return true;
}

export async function getTokenFromServer() {
  const url = ERouteApi.tokenCheckAndUpdate;

  const response = await httpRequest({
    dispatch: null,
    url,
    method: 'get',
    data: {},
    overideRequest: true,
  });

  return response;
}
